/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

/*export const onClientEntry = async () => {
    if (typeof IntersectionObserver === "undefined") {
      await import("intersection-observer")
    }
  }*/

  exports.onClientEntry = () => {
    // NOTE: Don't polyfill Promise here (Gatsby already includes a Promise polyfill)
  
    // IntersectionObserver polyfill for gatsby-image (Safari, IE)
    if (typeof window.IntersectionObserver === `undefined`) {
      require(`intersection-observer`)
      console.log(`👍 IntersectionObserver is polyfilled`)
    }
  
    // Object-fit/Object-position polyfill for gatsby-image (IE)
    const testImg = document.createElement(`img`)
    if (
      typeof testImg.style.objectFit === `undefined` ||
      typeof testImg.style.objectPosition === `undefined`
    ) {
      require(`object-fit-images`)()
      console.log(`👍 Object-fit/Object-position are polyfilled`)
    }
  }