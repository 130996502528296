// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-buyus-js": () => import("./../../../src/pages/buyus.js" /* webpackChunkName: "component---src-pages-buyus-js" */),
  "component---src-pages-concerts-js": () => import("./../../../src/pages/concerts.js" /* webpackChunkName: "component---src-pages-concerts-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-music-js": () => import("./../../../src/pages/music.js" /* webpackChunkName: "component---src-pages-music-js" */),
  "component---src-pages-press-info-js": () => import("./../../../src/pages/pressInfo.js" /* webpackChunkName: "component---src-pages-press-info-js" */),
  "component---src-pages-video-js": () => import("./../../../src/pages/video.js" /* webpackChunkName: "component---src-pages-video-js" */),
  "component---src-pages-words-js": () => import("./../../../src/pages/words.js" /* webpackChunkName: "component---src-pages-words-js" */)
}

